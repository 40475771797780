#banner__carousel{
	max-width: 1920px;
	width: 100%;
	overflow: hidden;
	margin-left: auto;
	margin-right: auto;
	position: relative;

	.carousel-inner{
		max-width: 1920px;
		width: 160%;
		position: relative;
		left: 50%;
		transform: translateX(-50%);
	}
	.carousel-controls{
		position: absolute;
		width: 100%;
		display: flex;
		justify-content: center;
		z-index: 300;
		left: 0px;
		bottom: 15%;

		a{
			display: flex;
			align-items: center;
			justify-content: center;
			width: 48px;
			height: 48px;
			position: relative;
			border-radius: 50%;
			margin: 0px 4px;
			color: #A91E23;
			transition: all 0.3s linear;
			font-size: 18px;
			line-height: 18px;

			&:hover{
				text-decoration: none;
				color: #fff;

				&:after{
					background-color: #A91E23;
				}
			}

			&:after{
				width: 100%;
				height: 100%;
				content: '';
				position: absolute;
				left: 0px;
				top: 0px;
				background-color: #fff;
				transition: all 0.3s linear;
				border-radius: 50%;
				z-index: -2;
			}
			&:before{
				width: 100%;
				height: 100%;
				content: '';
				position: absolute;
				left: 0px;
				top: 0px;
				background-image: url('../images/pontilhado.png');
				background-size: auto auto;
				background-position: center;
				background-repeat: no-repeat;
				z-index: -1;
			}

			&.banner__next{
				span{
					margin-left: 2px;
				}
			}
		}
	}
}
.quem__somos{
	padding: 30px 0px 0px 0px;
	background-image: url('../images/sobre__nos__bg.jpg');
	background-repeat: no-repeat;
	background-size: cover;
	background-position: center;
}
.titulo__secao{
	font-family: 'verdana';
	font-size: 14px;
	line-height: 18px;
	color: #595959;
	margin-bottom: 8px;
	position: relative;
	padding-left: 50px;

	&:before{
		content: '';
		position: absolute;
		top: calc(50% - 4px);
		left: 0px;
		width: 41px;
		height: 6px;
		background-image: url('../images/titulo__detalhe.png');
		background-size: auto auto;
		background-repeat: no-repeat;
		background-position: center;
	}
}
.sub__secao{
	font-family: 'verdana';
	color: #A91E23;
	font-size: 30px;
	line-height: 34px;
	margin-bottom: 16px;
}
.quem__desc{
	text-align: justify;
	margin-bottom: 14px;
	color: #a6999a;
}
.quem__cards{
	display: flex;
	margin-bottom: 14px;
}
.quem__card{
	display: flex;
	align-items: center;
	padding: 5px;
}
.quem__card--imagem{
	width: 76px;
	min-width: 76px;
	height: 76px;
	display: flex;
	align-items: center;
	justify-content: center;
	position: relative;
	margin-right: 10px;

	&:after{
		width: 100%;
		height: 100%;
		position: absolute;
		left: 0px;
		top: 0px;
		background-image: url('../images/pontilhado__quem.png');
		background-repeat: no-repeat;
		background-position: center;
		background-size: cover;
		content: '';
	}
}
.quem__card--titulo{
	font-family: 'verdana';
	font-size: 18px;
	line-height: 20px;
	color: #6a6864;
}
.conteudo__btn{
	display: inline-flex;
	align-items: center;
	justify-content: center;
	min-width: 204px;
	height: 50px;
	background-color: #A91E23;
	color: #fff;
	font-family: 'verdana';
	position: relative;
	padding: 6px 26px;
	margin-bottom: 10px;
	border: none;

	&:hover{
		text-decoration: none;
		color: #fff;

		.helper{
			&:after{
				opacity: 1;
			}
		}

		&:after{
			opacity: 1;
		}
		&:before{
			opacity: 1;
		}
	}
	&:focus{
		outline: none;
	}
	&:after{
		width: 6px;
		height: 6px;
		background-color: #fff;
		border-radius: 50%;
		position: absolute;
		top: calc(50% - 3px);
		left: 10px;
		content: '';
		opacity: 0;
		transition: all 0.3s linear;
	}
	&:before{
		width: 6px;
		height: 6px;
		background-color: #fff;
		border-radius: 50%;
		position: absolute;
		top: calc(50% - 3px);
		right: 10px;
		content: '';
		opacity: 0;
		transition: all 0.3s linear;
	}
	.helper{
		&:after{
			top: calc(100% + 3px);
			height: 4px;
			left: 0px;
			width: 100%;
			position: absolute;
			background-color: #A91E23;
			content: '';
			opacity: 0;
			transition: all 0.3s linear;
		}
	}
}
.servicos{
	padding: 56px 0px 50px 0px;
	overflow: hidden;
	position: relative;

	&:after{
		position: absolute;
		left: 50%;
		width: 1920px;
		content: '';
		transform: translateX(-50%);
		top: 0px;
		height: 100%;
		background-image: url('../images/bg__servicos.png');
		background-repeat: no-repeat;
		background-size: 1022px 560px;
		background-position: right top;
		z-index: -1;
	}
}
.servicos__header{
	margin-bottom: 20px;
}
.servico__imagem{
	width: 150px;
	min-width: 150px;
	height: 150px;
	display: flex;
	align-items: center;
	justify-content: center;
	position: relative;
	margin-left: auto;
	margin-right: auto;
	margin-bottom: 8px;

	&:after{
		mask-image: url('../images/servico__detalhe.png');
		mask-repeat: no-repeat;
		mask-size: cover;
		mask-position: center;
		background-color: #ece5da;
		content: '';
		position: absolute;
		width: 100%;
		height: 100%;
		transition: all 0.3s linear;
	}
}
.servico__icone{
	width: 70px;
	height: 70px;
	mask-repeat: no-repeat;
	mask-size: auto auto;
	mask-position: center;
	background-color: #a6999a;
	transition: all 0.3s linear;
}
.servico__card{
	display: block;
	width: 100%;
	margin-left: auto;
	margin-right: auto;
	padding: 0px 15px;

	&:hover{
		text-decoration: none;

		.servico__imagem{
			&:after{
				background-color: #A91E23;
			}
		}
		.servico__icone{
			background-color: #A91E23;
		}
		.servico__titulo{
			color: #A91E23;
		}
		.servico__desc{
			&:after{
				background-color: #A91E23;
			}
		}
	}
}
.servico__titulo{
	font-family: 'verdana';
	color: #a6999a;
	font-size: 18px;
	line-height: 20px;
	--linhas: 2;
	height: 40px;
	text-align: center;
	margin-bottom: 6px;
	transition: all 0.3s linear;
}
.servico__desc{
	text-align: center;
	font-size: 14px;
	line-height: 16px;
	height:80px;
	--linhas: 5;
	margin-bottom: 25px;
	position: relative;

	&:after{
		width: 10px;
		height: 10px;
		border-radius: 50%;
		content: '';
		position: absolute;
		left: calc(50% - 5px);
		top: calc(100% + 10px);
		background-color: #ece5da;
	}
}
.servicos__cima{
	display: flex;
	justify-content: space-between;
}
.servicos__controls{
	display: flex;
	align-items: center;

	a{
		width: 48px;
		height: 48px;
		display: flex;
		align-items: center;
		justify-content: center;
		background-color: #A91E23;
		color: #fff;
		position: relative;
		border-radius: 50%;
		margin: 0px 5px;
		font-size: 18px;
		line-height: 18px;

		&:hover{
			text-decoration: none;
			color: #fff;

			&:after{
				opacity: 1;
			}
		}

		&:after{
			width: 100%;
			height: 100%;
			content: '';
			position: absolute;
			left: 0px;
			top: 0px;
			background-image: url('../images/pontilhado.png');
			background-repeat: no-repeat;
			background-size: auto auto;
			background-position: center;
			transition: all 0.3s linear;
			opacity: 0;
		}

		&.servicos__next{
			span{
				margin-left: 2px;
			}
		}
		&.servicos__prev{
			span{
				margin-right: 2px;
			}
		}
	}
}
.comentario__card--cima{
	display: flex;
	align-items: center;
	max-width: calc(100% - 100px);
	margin-bottom: 10px;
}
.comentario__card--imagem{
	position: relative;
	width: 100px;
	min-width: 100px;
	height: 100px;
	display: flex;
	align-items: center;
	justify-content: center;
	margin-right: 16px;

	img{
		border-radius: 50%;
	}

	&:after{
		width: 100%;
		height: 100%;
		background-image: url('../images/comentario__after.png');
		background-repeat: no-repeat;
		background-position: center;
		background-size: cover;
		content: '';
		position: absolute;
		left: 0px;
		top: 0px;
	}
}
.comentario__card--titulo{
	font-family: 'verdana';
	font-size: 18px;
	line-height: 20px;
	color: #6a6864;
	margin-bottom: 2px;
}
.comentario__card--descricao{
	color: #848484;
	font-size: 14px;
	line-height: 16px;
}
.comentario__card--desc{
	height: calc(16px * 4);
	font-size: 14px;
	line-height: 16px;
	overflow-y: auto;
	text-align: justify;
	padding-right: 5px;

	&::-webkit-scrollbar{
		width: 5px;
	}
	&::-webkit-scrollbar-track{
		background-color: #fff;
	}
	&::-webkit-scrollbar-thumb{
		background-color: #A91E23;
	}
}
#comentario__carousel{
	.carousel-controls{
		display: flex;
		z-index: 200;

		a{
			display: flex;
			align-items: center;
			justify-content: center;
			width: 48px;
			height: 48px;
			position: relative;
			border-radius: 50%;
			margin: 0px 4px;
			color: #fff;
			transition: all 0.3s linear;
			font-size: 18px;
			line-height: 18px;

			&:hover{
				text-decoration: none;
				color: #fff;

				&:before{
					opacity: 1;
				}
			}

			&:after{
				width: 100%;
				height: 100%;
				content: '';
				position: absolute;
				left: 0px;
				top: 0px;
				background-color: #A91E23;
				border-radius: 50%;
				z-index: -2;
			}
			&:before{
				width: 100%;
				height: 100%;
				content: '';
				position: absolute;
				left: 0px;
				top: 0px;
				background-image: url('../images/pontilhado.png');
				background-size: auto auto;
				background-position: center;
				background-repeat: no-repeat;
				z-index: -1;
				opacity: 0;
				transition: all 0.3s linear;
			}

			&.banner__next{
				span{
					margin-left: 2px;
				}
			}
		}
	}
}
.comentarios{
	position: relative;
	z-index: 10;
}
.noticias{
	position: relative;
	z-index: 8;
	padding: 124px 0px 54px 0px;
	overflow: hidden;
	margin-top: -88px;

	&:before{
		width: 100%;
		height: 100%;
		content: '';
		position: absolute;
		left: 0px;
		top: 0px;
		background-image: url('../images/bg__noticia.png');
		background-repeat: no-repeat;
		background-size: cover;
		background-position: center;
		z-index: -1;
	}
}
.noticias__header{
	display: flex;
	flex-direction: column;
	align-items: center;
	text-align: center;
	margin-bottom: 15px;
	max-width: 800px;
	width: 100%;
	margin-left: auto;
	margin-right: auto;
}
.noticias__owl{
	&.owl-carousel{
		.owl-stage-outer{
			overflow: visible;
		}
	}
	.owl-dots{
		display: none;
	}
}
.noticia__card{
	display: block;
	max-width: 360px;
	margin-left: auto;
	margin-right: auto;
	background-color: #b4a68a;
	position: relative;

	&:after{
		width: 202px;
		height: 205px;
		content: '';
		position: absolute;
		top: 10px;
		right: 10px;
		background-image: url('../images/detalhe__noticia.png');
		background-repeat: no-repeat;
		background-size: cover;
		background-position: center;
		z-index: 200;
	}

	&:hover{
		.noticia__card--imagem{
			opacity: 1;
		}
	}
}
.noticia__card--imagem{
	position: relative;
	z-index: 2;
	opacity: 0.2;
	transition: all 0.3s linear;
}
.noticia__card--conteudo{
	position: absolute;
	z-index: 10;
	bottom: 20px;
	padding-left: 30px;

	&:before{
		width: 20px;
		position: absolute;
		content: '';
		background-color: #fff;
		left: 0px;
		top: 0px;
		height: 100%;
	}
}
.noticia__card__titulo{
	font-family: 'verdana';
	font-size: 15px;
	line-height: 17px;
	color: #fff;
	text-shadow: 0px 0px 2px rgba(#000, 0.9);
	margin-bottom: 3px;
}
.noticia__card__data{
	display: flex;
	align-items: center;
	font-size: 13px;
	line-height: 15px;
	color: #fff;
	text-shadow: 0px 0px 2px rgba(#000, 0.9);
	font-family: 'verdanamedium';
}
.icone__calendario{
	width: 17px;
	height: 16px;
	display: inline-block;
	background-image: url('../images/data__icone.png');
	background-repeat: no-repeat;
	background-size: cover;
	background-position: center;
	margin-right: 10px;
}
.segura__noticia__btn{
	display: flex;
	justify-content: center;
	padding-top: 20px;
}
.noticias__owl.owl-carousel{
	.owl-nav{
		button.owl-prev,
		button.owl-next{
			position: absolute;
			width: 48px;
			height: 48px;
			display: flex;
			align-items: center;
			justify-content: center;
			border-radius: 50%;
			top: calc(50% - 24px);
			border: none;
			color: #fff;
			font-size: 18px;
			line-height: 18px;
			z-index: 300;

			&:focus{
				outline: none;
			}

			&:hover{
				&:before{
					opacity: 1;
				}
			}

			&:after{
				width: 100%;
				height: 100%;
				content: '';
				position: absolute;
				left: 0px;
				top: 0px;
				background-color: #A91E23;
				border-radius: 50%;
				z-index: -2;
			}
			&:before{
				width: 100%;
				height: 100%;
				content: '';
				position: absolute;
				left: 0px;
				top: 0px;
				background-image: url('../images/pontilhado.png');
				background-size: auto auto;
				background-position: center;
				background-repeat: no-repeat;
				z-index: -1;
				opacity: 0;
				transition: all 0.3s linear;
			}
		}
		.owl-prev{
			left: 0px;

			i{
				margin-right: 2px;
			}
		}
		.owl-next{
			right: 0px;

			i{
				margin-left: 2px;
			}
		}
	}
}
.segura__noticia__btn{
	padding-top: 30px;
}
.cadastro{
	padding: 40px 0px;
}
.cadastro__titulo{
	font-family: 'verdanaextrabold';
	font-size: 18px;
	line-height: 22px;
	color: #A91E23;
	text-align: center;
	margin-bottom: 4px;
	max-width: 460px;
	width: 100%;
	margin-right: auto;
	margin-left: auto;
}
.cadastro__desc{
	width: 340px;
	max-width: 100%;
	margin-right: auto;
	margin-left: auto;
	color: #6d6d6d;
	font-size: 14px;
	line-height: 16px;
	font-family: 'verdanamedium';
	text-align: center;
}
.cadastro__form{
	padding: 0px 24px;

	.form-group{
		margin-bottom: 8px;
	}
}
.cadastro__input{
	display: block;
	width: 100%;
	border: 2px solid #d1d1d1;
	height: auto;
	padding: 13px 18px 11px 18px;
	color: #878787;
	font-family: 'verdanaitalic';
	font-size: 13px;
	line-height: 16px;
	transition: all 0.3s linear;

	&:focus{
		outline: none;
		border: 2px solid #A91E23;
	}
}
.segura__cadastro__btn{
	display: flex;
	justify-content: center;
	padding-top: 15px;
}
@include media-breakpoint-up (lg) {
	.quem__somos,
	.comentarios{
		max-width: 1920px;
		width: 100%;
		margin-right: auto;
		margin-left: auto;
		position: relative;
		overflow: hidden;
	}
	.quem__inner,
	.comentarios__inner{
		position: relative;
		max-width: 1920px;
		width: 160%;
		left: 50%;
		transform: translateX(-50%);
		display: flex;
		justify-content: center;
	}
	.quem__col--imagem,
	.quem__col--texto,
	.comentarios__col--imagem,
	.comentarios__col--texto{
		width: 50%;
		display: flex;
	}
	.quem__col--imagem,
	.comentarios__col--imagem{
		align-items: flex-end;
	}
	.quem__col--texto,
	.comentarios__col--texto{
		padding-bottom: 50px;
		display: flex;
		align-items: center;
	}
	.quem__texto,
	.comentarios__conteudo{
		max-width: 600px;
		padding-right: 15px;
		padding-left: 15px;
	}
	.servicos__header{
		max-width: 500px;
	}
	#comentario__carousel{
		.carousel-controls{
			position: absolute;
			right: 15px;
			top: 24px;
			display: flex;
			align-items: center;
		}
	}
	.cadastro{
		.container{
			background-image: url('../images/cadastro__borda1.png');
			background-size: 1140px 417px;
			background-repeat: no-repeat;
			height: 417px;
			padding-top: 30px;
			padding-bottom: 30px;
			position: relative;

			&:after{
				content: '';
				position: absolute;
				width: 56px;
				height: 71px;
				background-image: url('../images/cadastro__detalhe.png');
				background-repeat: no-repeat;
				background-size: cover;
				background-position: center;
				right: 70px;
				top: -30px;
			}
		}
	}
	.cadastro__desc{
		overflow: hidden;
		display: -webkit-box;
		-webkit-box-orient: vertical;
		-webkit-line-clamp: 2;
		max-height: 32px;
		margin-bottom: 14px;
	}
	.cadastro__titulo{
		text-overflow: ellipsis;
		overflow: hidden;
		white-space: nowrap;
	}
}
@include media-breakpoint-only (lg) {
	.quem__texto,
	.comentarios__conteudo{
		max-width: 496px;
	}
	.quem__cards{
		flex-direction: column;
	}
	.cadastro{
		.container{
			background-image: url('../images/cadastro__borda2.png');
			background-size: 960px 417px;
			background-repeat: no-repeat;
			height: 417px;
			padding-top: 30px;
			padding-bottom: 30px;
			position: relative;
		}
	}
}
@include media-breakpoint-down (lg) {
	#comentario__carousel{
		.carousel-controls{
			position: relative;
			right: 0px;
			left: 0px;
			top: 0px;
			display: flex;
			align-items: center;
			padding-top: 16px;
			margin-bottom: 10px;
		}
	}
}
@include media-breakpoint-down (md) {
	.quem__somos{
		.container{
			display: flex;
			flex-direction: column;

			.quem__col--imagem{
				order: 2;
			}
			.quem__col--texto{
				order: 1;
				margin-bottom: 20px;
			}
		}
	}
	.conteudo__btn{
		&:after,
		&:before{
			opacity: 1;
		}
		.helper{
			&:after{
				opacity: 1;
			}
		}
	}
	.segura__quem__btn{
		display: flex;
		justify-content: center;
	}
	.servicos__cima{
		display: flex;
		flex-direction: column;
		align-items: center;
		margin-bottom: 20px;

		.servicos__header{
			margin-bottom: 0px;
			display: flex;
			flex-direction: column;
			align-items: center;
		}
		.sub__secao{
			text-align: center;
		}
	}
	.servico__card{
		.servico__imagem{
			&:after{
				background-color: #A91E23;
			}
		}
		.servico__icone{
			background-color: #A91E23;
		}
		.servico__titulo{
			color: #A91E23;
		}
		.servico__desc{
			&:after{
				background-color: #A91E23;
			}
		}
	}
	.comentarios__inner{
		display: flex;
		flex-direction: column;

		.comentarios__col--imagem{
			order: 2;
		}
		.comentarios__col--texto{
			order: 1;
			margin-bottom: 10px;
		}
	}
	#comentario__carousel{
		.carousel-controls{
			justify-content: center;
		}
	}
	.noticia__card{
		.noticia__card--imagem{
			opacity: 1;
		}
	}
	.cadastro{
		.container{
			border: 2px solid #8b7c72;
			padding-top: 30px;
			padding-bottom: 30px;
		}
	}
	.comentario__card--cima{
		max-width: 100%;
	}
}
@include media-breakpoint-down (sm) {
	.quem__cards,
	.quem__card{
		flex-direction: column;
		align-items: center;
		text-align: center;

		.quem__card--imagem{
			margin-bottom: 10px;
		}
	}
	.quem__card{
		margin-bottom: 10px;
	}
	.quem__cards{
		margin-bottom: 0px;
	}
	.sub__secao{
		font-size: 24px;
		line-height: 28px;
	}
	.comentario__card--cima{
		flex-direction: column;
		align-items: center;
		text-align: center;

		.comentario__card--imagem{
			margin-right: 0px;
			margin-bottom: 8px;
		}
	}
}
@include media-breakpoint-down (xs) {
	.cadastro{
		.cadastro__form{
			padding: 0px 5px;
		}
	}
}
