.main__menu{
	padding-left: 0px;
	margin-bottom: 0px;
	list-style: none;
}
@include media-breakpoint-up (lg) {
	.topo__mobile{
		display: none;
	}
	.topo__main{
		padding: 20px 0px;

		.container{
			display: flex;
			justify-content: space-between;
		}
	}
	.topo{
		position: relative;
		z-index: 40;

		&.topo--home{
			.topo__main{
				position: absolute;
				width: 100%;
			}
		}
		&.topo--interna{
			.topo__main{
				background-image: url('../images/bg__topo.jpg');
				background-size: 1920px 611px;
				background-repeat: no-repeat;
				background-position: center;
			}
		}
	}
	.wrapper{
		position: relative;
		z-index: 20;
	}
	.main__conteudo{
		display: flex;
	}
	.main__menu{
		display: flex;
		margin-right: 40px;
		position: relative;

		&:after{
			width: 2px;
			height: 12px;
			background-color: #fff;
			position: absolute;
			content: '';
			top: calc(50% - 6px);
			right: -20px;
		}
	}
	.menu__item{
		display: flex;
		margin: 0px 8px;
		position: relative;

		.dropmenu{
			position: absolute;
			width: 170px;
			left: calc(50% - 85px);
			top: calc(50% + 30px);
			background-color: #fff;
			box-shadow: 0px 0px 5px rgba(#000, 0.5);
			padding-left: 0px;
			margin-bottom: 0px;
			list-style: none;
			padding: 2px 18px;

			&:after{
				border-bottom: 10px #fff solid;
				border-left: 10px transparent solid;
				border-right: 10px transparent solid;
				content: '';
				position: absolute;
				top: -9px;
				left: calc(50% - 10px);
			}

			a{
				display: block;
				font-family: 'verdanasemibold';
				font-size: 13px;
				line-height: 13px;
				color: #000;
				text-align: center;
				padding: 10px;
				border-bottom: 1px solid #ededed;

				&:hover{
					text-decoration: none;
					color: #A91E23;
				}
			}
			li{
				&:last-child{
					a{
						border-bottom: none;
					}
				}
			}
		}

		&:hover{
			.dropmenu{
				display: block;
			}
		}

		&.menu__item--active{
			.menu__link{
				color: #A91E23;
			}
		}

		.menu__sub__controls{
			display: flex;
		}
		button{
			display: none;
		}
	}
	.menu__link{
		display: flex;
		color: #fff;
		align-items: center;
		font-family: 'verdana';
		font-size: 14px;
		line-height: 14px;
		transition: all 0.3s linear;

		&:hover{
			color: #A91E23;
			text-decoration: none;
		}
	}
	.main__sociais{
		font-family: 'verdana';
		font-size: 14px;
		line-height: 14px;
		color: #fff;
		display: flex;
		align-items: center;

		nav{
			display: flex;
			a{
				display: flex;
				align-items: center;
				justify-content: center;
				border-radius: 50%;
				width: 39px;
				height: 39px;
				position: relative;
				color: #000;
				margin: 0px 2px;
				transition: all 0.3s linear;

				&:hover{
					text-decoration: none;
					color: #fff;

					&:after{
						opacity: 0;
					}
				}

				&:after{
					position: absolute;
					left: 0px;
					top: 0px;
					background-color: #fff;
					border-radius: 50%;
					width: 100%;
					height: 100%;
					content: '';
					z-index: -1;
					transition: all 0.3s linear;
				}
				&:before{
					position: absolute;
					left: 0px;
					top: 0px;
					width: 100%;
					height: 100%;
					content: '';
					z-index: -2;
					transition: all 0.3s linear;
					background-image: url('../images/pontilhado__topo.png');
					background-repeat: no-repeat;
					background-position: center;
					background-size: 100% 100%;
				}
			}
		}
	}
	.sociais__titulo{
		margin-right: 6px;
	}
}
@include media-breakpoint-only (lg) {
	.main__logo{
		max-width: 180px;
	}
	.main__menu{
		margin-right: 20px;

		&:after{
			right: -10px;
		}
	}
	.main__sociais{
		nav{
			a{
				width: 30px;
				height: 30px;
			}
		}
	}
}
@include media-breakpoint-down (md) {
	.topo__mobile{
		.container{
			display: flex;
			justify-content: space-between;
		}
	}
	.topo__main{
		position: fixed;
		left: -250px;
		top: 0px;
		transition: all 0.5s linear;
		width: 250px;
		height: 100%;
		background-image: url('../images/bg__topo.jpg');
		background-repeat: no-repeat;
		background-position: center;
		background-size: cover;
		z-index: 1080;

		.container{
			padding: 0px;
		}

		&.shown{
			left: 0px;
		}
	}
	.bg__menu{
		position: fixed;
		left: 0px;
		top: 0px;
		background-color: rgba(#000, 0.6);
		animation: fadeIn 0.5s linear;
		width: 100%;
		height: 100%;
		z-index: 1050;

		&.hide{
			animation: fadeOut 0.5s linear;
		}
	}
	.main__logo{
		text-align: center;
		padding: 5px;
		margin-bottom: 10px;
	}
	.menu__link{
		display: block;
		padding: 5px 5px;
		color: #fff;
		width: 100%;
		font-family: 'verdanasemibold';
		transition: all 0.3s linear;

		&:hover{
			text-decoration: none;
			color: #A91E23;
		}
	}
	.menu__item{
		margin: 5px 0px;

		&.menu__item--active{
			.menu__link{
				color: #A91E23;
			}
		}
	}
	.menu__sub__controls{
		position: relative;

		button{
			position: absolute;
			width: 20px;
			height: 20px;
			border: none;
			border-radius: 50%;
			top: calc(50% - 10px);
			right: 10px;
			display: flex;
			align-items: center;
			justify-content: center;

			&:after{
				font-weight: 900;
				font-family: 'Font Awesome 5 Free';
				content: '\f078';
				font-size: 12px;
				line-height: 12px;
				margin-top: 2px;
			}
		}
	}
	.main__menu{
		margin-bottom: 15px;
	}
	.main__sociais{
		display: flex;
		flex-direction: column;
		align-items: center;
		color: #fff;
		font-family: "verdana";

		.sociais__titulo{
			margin-bottom: 5px;
			font-size: 16px;
			line-height: 18px;
		}
		nav{
			display: flex;
			justify-content: center;

			a{
				display: block;
				font-size: 20px;
				line-height: 20px;
				margin: 0px 5px;
			}
		}
	}
	.topo__mobile{
		padding: 10px 0px;
		background-image: url('../images/bg__topo.jpg');
		background-repeat: no-repeat;
		background-position: center;
		background-size: cover;

		.container{
			display: flex;
			justify-content: space-between;
			align-items: center;
		}
	}
	.mobile__logo{
		max-width: 180px;
	}
	.mbl__toggler{
		border: none;
		background-color: transparent;
		font-size: 26px;
		line-height: 26px;
		color: #fff;

		&:focus{
			outline: none;
		}
	}
	.menu__item{
		.menu__sub__controls{
			button{
				transition: all 0.3s linear;
				&:focus{
					outline: none;
				}
				&[aria-expanded="true"] {
					transform: rotate(90deg);
				}
			}
		}
		.dropmenu{
			padding-left: 0px;
			list-style: none;

			a{
				display: block;
				background-color: #A91E23;
				color: #fff;
				padding: 8px 15px;
				border-bottom: 1px solid #fff;
			}
		}
	}
}
@include media-breakpoint-down (sm) {
	.mobile__logo{
		max-width: 140px;
	}
}
