.filtro__imoveis{
	margin-bottom: 30px;
}
.filtro__imoveis{
    display: grid;
    grid-template-columns: calc(50% - 10px) calc(50% - 10px);
    column-gap: 20px;

    .form-group{
        margin-bottom: 15px;
        position: relative;

        .segura__range__btn{
            height: 44px;
            display: flex;
            flex-direction: column;
        }

        .custom__range__texto{
            position: absolute;
            top: -1px;

            input{
                padding: 0px;
                background-color: transparent;
                border: none;
                font-family: 'verdana';
                font-size: 15px;
                line-height: 19px;
                position: relative;
				color: #4f4e4d;
				pointer-events: none;


                &:after{
                    position: absolute;
                    top: 0px;
                    left: 0px;
                    width: 100%;
                    height: 100%;
                    content: '';
                    z-index: 10;
                }
            }
        }

        &:nth-child(5) {
            height: 44px;

            .custom__range__texto{
                left: 50px;
            }
        }
        &:nth-child(6) {
            height: 44px;
            .custom__range__texto{
                left: 110px;
            }
        }
    }

    .v-select-output{
        background-color: #ededed;
        border-radius: 24px;
        font-family: 'verdana';
        font-size: 14px;
        line-height: 18px;
        height: auto;
        padding: 16px 20px 14px 20px;
        border: none;
		color: #4f4e4d;

		&:before{
			margin-top: 6px;
		}

        &:focus{
            outline: none;
            box-shadow: 0px 0px 10px rgba(#000, 0.3);
        }
    }
    .label__valor{
        font-family: 'verdana';
        font-size: 14px;
        line-height: 18px;
        color: #4f4e4d;
        margin-bottom: 0px;
    }
    .segura__botao{
        display: flex;

        .custom__range__texto{
            position: absolute;
        }

        .segura__range__btn{
            display: flex;
            flex-direction: column;
            flex-grow: 1;
            position: relative;
        }
    }
}
.pesquisa__btn{
    width: 148px;
    height: 44px;
    min-height: 44px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #A91E23;
    color: #fff;
    font-family: 'verdanamedium';
    font-size: 14px;
	line-height: 14px;
	padding-bottom: 2px;
    border: none;
    padding-top: 4px;
    margin-left: 15px;
    position: relative;
	transition: all 0.3s linear;

	&:after{
		width: 6px;
		height: 6px;
		background-color: #fff;
		border-radius: 50%;
		position: absolute;
		top: calc(50% - 3px);
		left: 10px;
		content: '';
		opacity: 0;
		transition: all 0.3s linear;
	}
	&:before{
		width: 6px;
		height: 6px;
		background-color: #fff;
		border-radius: 50%;
		position: absolute;
		top: calc(50% - 3px);
		right: 10px;
		content: '';
		opacity: 0;
		transition: all 0.3s linear;
	}

    &:hover{
        background-color: #A91E23;
        color: #fff;
		box-shadow: 0px 0px 3px rgba(#000, 0.3);

		&:before,
		&:after{
			opacity: 1;
		}
    }
}
.custom__range{
    .range__input{
        appearance: none;
        width: 100%;
        height: 3px;
        background-color: #b0aca4;
        background-image: -webkit-gradient(linear, 50% 0%, 50% 100%, color-stop(0%, #A91E23), color-stop(100%, #A91E23));
        background-size: 50% 100%;
        background-repeat: no-repeat;
        outline: none;
        opacity: 1;

        &::-webkit-slider-thumb{
            width: 16px;
            height: 16px;
            border-radius: 50%;
            background-color: #b0aca4;
            position: relative;
            appearance: none;
            border: 3px solid #ededed;
            cursor: pointer;
            transition: all 0.3s linear;
            box-shadow: 0px 0px 3px rgba(#000, 0.6);

            &:hover{
                background-color: #A91E23;
            }
        }
    }
}
.imovel__card--imagem{
	display: block;
	padding: 20px;
	background-image: url('../images/bg__borda.png');
	background-repeat: no-repeat;
	background-size: 100% 100%;
	background-position: center;
	max-width: 270px;
	width: 100%;
	margin-left: auto;
	margin-right: auto;
	margin-bottom: 8px;
	min-height: 270px;
	display: flex;
	align-items: center;
	justify-content: center;
	text-align: center;
}
.imovel__card{
	display: block;
	max-width: 270px;
	width: 100%;
	margin-left: auto;
	margin-right: auto;
	margin-bottom: 24px;

	img{
		transition: all 0.4s linear;
	}
	&:hover{
		text-decoration: none;

		img{
			opacity: 0.4;
		}
	}
}
.imovel__card--header{
	display: flex;
	align-items: center;
	font-family: 'verdana';
	color: #828282;
	margin-bottom: 26px;
	position: relative;
	padding-left: 2px;

	&:after{
		width: 100%;
		height: 10px;
		content: '';
		background-image: url('../images/bg__estrela.jpg');
		background-repeat: repeat-x;
		background-size: 16px 10px;
		background-position: left top;
		position: absolute;
		top: calc(100% + 6px);
		left: 0px;
	}
}
.imovel__card--titulo{
	font-family: 'verdana';
	color: #A91E23;
	font-size: 15px;
	line-height: 17px;
	margin-bottom: 4px;
	--linhas: 1;
	height: 17px;
}
.imovel__card--endereco{
	font-size: 13px;
	line-height: 15px;
	margin-bottom: 5px;
	text-align: justify;
	--linhas: 3;
	height: 45px;
}
.imovel__card--valor{
	font-family: 'verdana';
	font-size: 16px;
	line-height: 20px;
	margin-bottom: 8px;
	color: #4f4e4d;
	padding-top: 6px;
}
.imovel__card--informacoes{
	background-color: #A91E23;
	color: #dbc4a2;
	padding: 8px 5px;
	display: flex;
	justify-content: center;
	align-items: center;
	flex-wrap: wrap;
	min-height: 60px;

	.imovel__card--dado{
		display: flex;
		align-items: center;
		padding: 0px 9px;
		position: relative;
		margin-top: 2px;
		margin-bottom: 2px;

		.cama__icone,
		.chuveiro__icone,
		.carro__icone,
		.tamanho__icone{
			margin-right: 4px;
		}

		&:last-child{
			&:after{
				display: none;
			}
		}

		&:after{
			width: 2px;
			height: 10px;
			content: '';
			position: absolute;
			top: calc(50% - 5px);
			right: -1px;
			background-color: #dbc4a2;
		}
	}
}
.cama__icone{
	display: block;
	width: 18px;
	height: 13px;
	background-image: url('../images/icone__cama.png');
	background-repeat: no-repeat;
	background-size: cover;
	background-position: center;
}
.chuveiro__icone{
	display: block;
	width: 13px;
	height: 15px;
	background-image: url('../images/chuveiro__icone.png');
	background-repeat: no-repeat;
	background-size: cover;
	background-position: center;
}
.carro__icone{
	display: block;
	width: 15px;
	height: 14px;
	background-image: url('../images/carro__icon.png');
	background-repeat: no-repeat;
	background-size: cover;
	background-position: center;
}
.tamanho__icone{
	display: block;
	width: 15px;
	height: 16px;
	background-image: url('../images/tamanho__icone.png');
	background-repeat: no-repeat;
	background-size: cover;
	background-position: center;
}
.row__miniaturas.carousel-indicators{
	padding-top: 14px;
	justify-content: center;
	position: relative;
	padding-left: 0px;
	margin-bottom: 0px;
	list-style: none;
	margin-left: 0px;
	margin-right: 0px;

	.col-auto{
		margin-bottom: 10px;
		margin-right: 10px;
		margin-left: 10px;
		position: relative;
		height: auto;
		width: auto;
		padding: 0px;
		border: none;
		opacity: 1;

		&:after{
			width: 100%;
			height: 100%;
			content: '';
			position: absolute;
			z-index: 2;
			top: 0px;
			left: 0px;
			border: 2px solid #A91E23;
			opacity: 0;
		}

		&.active{
			&:after{
				opacity: 1;
			}
		}

		img{
			display: block;
			position: relative;
			transition: all 0.3s linear;
			text-indent: 1;
		}

		.zoomGalleryActive{
			&:after{
				opacity: 0.6;
			}
		}
	}
}
.row__produto__interna{
	margin-bottom: 40px;

	.col-lg-6{
		&:first-child{
			position: relative;
			z-index: 4;
		}
		&:last-child{
			position: relative;
			z-index: 3;
		}
	}
}
.imovel__detalhes__titulo{
	font-family: 'verdana';
	font-size: 30px;
	line-height: 34px;
	color: #A91E23;
	margin-bottom: 6px;
}
.imovel__detalhes__header{
	font-family: 'verdanasemibold';
	font-size: 18px;
	line-height: 22px;
	color: #a6999a;
	display: flex;
	margin-bottom: 10px;
}
.imovel__detalhes__desc{
	text-align: justify;
	margin-bottom: 34px;
	position: relative;

	&:after{
		width: 100%;
		height: 10px;
		content: '';
		background-image: url('../images/bg__estrela.jpg');
		background-repeat: repeat-x;
		background-size: 16px 10px;
		background-position: left top;
		position: absolute;
		top: calc(100% + 10px);
		left: 0px;
	}
}
.imovel__descricao__titulo{
	font-family: 'verdana';
	color: #A91E23;
	font-size: 16px;
	line-height: 20px;
	margin-bottom: 18px;
}
.imovel__detalhe__descricao{
	margin-bottom: 20px;
}
.imovel__descricao__texto{
	font-family: 'verdanamedium';
	font-size: 14px;
	line-height: 18px;
	color: #525252;
}
.imovel__detalhes__valor{
	font-family: 'verdana';
	font-size: 24px;
	line-height: 28px;
	color: #A91E23;
}
.imovel__detalhes__colunas{
	display: grid;
	grid-template-columns: calc(50% - 15px) calc(50% - 15px);
	column-gap: 30px;

	.imovel__coluna__item{
		margin-bottom: 5px;
		font-family: 'verdanamedium';
		color: #a69f99;
	}
}
.segura__anterior__btn{
	padding-top: 20px;
}
@include media-breakpoint-up (lg) {
	.imovel__detalhes__info{
		display: flex;
		padding-top: 10px;
		align-items: center;
		margin-bottom: 18px;

		.imovel__detalhes__valor{
			width: 50%;
		}
		.imovel__card--informacoes{
			width: 50%;
		}
	}
}
@include media-breakpoint-only (lg) {
	.imovel__detalhes__info{
		display: flex;
		flex-direction: column;
		padding-top: 10px;
		align-items: center;
		margin-bottom: 18px;

		.imovel__detalhes__valor{
			width: 100%;
		}
		.imovel__card--informacoes{
			width: 100%;
		}
	}
	.imovel__detalhes__valor{
		margin-bottom: 10px;
		text-align: center;
	}
	.segura__anterior__btn{
		display: flex;
		justify-content: center;
	}
}
@include media-breakpoint-down (md) {
	.produto__figure{
		text-align: center;
		margin-bottom: 20px;
	}
	.angulos__txt{
		text-align: center;
	}
	.row__miniaturas{
		justify-content: center;
		margin-bottom: 30px;

		.col__auto{
			&:first-child{
				margin-left: 10px;
			}
		}
	}
	.row__produto__interna{
		.col-lg-6{
			&:first-child{
				order: 2;
			}
			&:last-child{
				order: 1;
			}
		}
	}
	.visualizar__carousel{
		max-width: 630px;
		width: 100%;
		margin-right: auto;
		margin-left: auto;
	}
	.imovel__detalhes__info{
		margin-bottom: 12px;

		.imovel__detalhes__valor{
			margin-bottom: 6px;
		}
	}
	.segura__anterior__btn{
		justify-content: center;
		display: flex;
	}
}
@include media-breakpoint-down (sm) {
	.filtro__imoveis{
		display: block;

		.form-group{
			&:nth-child(6) {
				height: auto;
			}
		}
	}
	.form-group{
		&.segura__botao{
			flex-direction: column;
			align-items: center;
		}
	}
	.segura__range__btn{
		width: 100%;
	}
}
