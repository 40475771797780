.produto__interna--header{
	text-align: center;
	max-width: 940px;
	width: 100%;
	margin-left: auto;
	margin-right: auto;
	margin-bottom: 30px;

	.sub__secao{
		margin-bottom: 5px;
		max-width: 580px;
		width: 100%;
		margin-left: auto;
		margin-right: auto;
	}
}
.produto__interna{
	.servico__card{
		margin-bottom: 50px;
	}
}
.segura__voltar{
	padding-top: 10px;
	display: flex;
	justify-content: flex-end;
}
.pagination{
	display: flex;
	justify-content: center;

	.page-item{
		&:last-child,
		&:first-child{
			.page-link{
				border-radius: 50%;
			}
		}
		&.active{
			.page-link{
				background-color: #A91E23;
				border: none;
			}
		}
		.page-link{
			width: 30px;
			height: 30px;
			border-radius: 50%;
			background-color: #b0aca4;
			color: #fff;
			font-family: 'verdana';
			font-size: 13px;
			line-height: 13px;
			display: flex;
			align-items: center;
			justify-content: center;
			margin: 0px 2px;
			border: none;
			transition: all 0.3s linear;

			&:hover{
				background-color: #A91E23;
				text-decoration: none;
			}
		}
	}
}
.caret__left{
	display: inline-block;
	border-top: 6px solid transparent;
	border-bottom: 6px solid transparent;
	border-right: 8px solid #fff;
	margin-right: 2px;
}
.caret__right{
	display: inline-block;
	border-top: 6px solid transparent;
	border-bottom: 6px solid transparent;
	border-left: 8px solid #fff;
	margin-left: 2px;
}
@include media-breakpoint-down (sm) {
	.filtro__imoveis{
		display: block;
	}
}
