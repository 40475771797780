.noticias__categorias--listagem{
	display: flex;
	justify-content: center;
	flex-wrap: wrap;
	padding: 5px 10px;
	border: 2px dotted #A91E23;
	max-width: 750px;
	width: 100%;
	margin-right: auto;
	margin-left: auto;
	border-radius: 26px;
	margin-bottom: 26px;
}
.noticia__categoria{
	display: block;
	margin: 4px 10px;
	font-family: 'verdanasemibold';
	color: #A91E23;
	transition: all 0.3s linear;

	&:hover{
		text-decoration: none;
	}

	&.noticia__categoria--active{
		color: #aeaaa1;
	}
}
.noticias__listagem{
	.col-lg-4{
		margin-bottom: 30px;
	}
}
.icone__data2{
	display: inline-block;
	width: 17px;
	height: 16px;
	background-image: url('../images/data__icone2.png');
	background-repeat: no-repeat;
	background-size: cover;
	background-position: center;
	margin-right: 5px;
}
.noticia__detalhe__data{
	display: flex;
	align-items: center;
	margin-bottom: 15px;
}
.noticia__detalhe__conteudo{
	.sub__secao{
		margin-bottom: 10px;
	}
}
@include media-breakpoint-down (md) {
	.noticia__detalhe__imagem{
		text-align: center;
		margin-bottom: 20px;
	}
}
@include media-breakpoint-only (xs) {
	.breadcrumb__custom{
		.container{
			flex-direction: column;
			align-items: center;
			text-align: center;
		}
	}
}
