.rodape__atender{
	background-color: #A91E23;
	color: #fff;
	padding: 32px 0px;

	.row{
		align-items: center;
	}
}
.segura__atender__btn{
	display: flex;
	justify-content: flex-end;
	align-items: center;

	.conteudo__btn{
		background-color: #fff;
		color: #A91E23;
		margin-top: 10px;

		&:after,
		&:before{
			background-color: #A91E23;
		}

		.helper{
			&:after{
				background-color: #fff;
			}
		}
	}
}
.titulo__atender{
	font-family: 'verdanalight';
	font-size: 23px;
	line-height: 27px;
	color: #fff;
}
.rodape__conteudo{
	padding-top: 40px;
	background-image: url('../images/rodape__bg.jpg');
	background-repeat: no-repeat;
	background-position: center;
	background-size: cover;
	color: #fff;
}
.rodape__creditos{
	padding: 22px 0px;
	border-top: 1px solid #a9a59d;
	margin-top: 20px;
}
.rodape__logo{
	margin-bottom: 10px;
	text-align: center;
}
.rodape__desc{
	text-align: justify;
}
.rodape__titulo{
	font-family: 'verdanaextrabold';
	font-size: 16px;
	line-height: 20px;
	color: #fff;
	margin-bottom: 28px;
	padding-top: 10px;
}
.rodape__nav{
	display: grid;
	grid-template-columns: 1fr 1fr;

	a{
		display: block;
		padding: 6px 0px;
		margin-bottom: 8px;
		font-family: 'verdana';
		font-size: 13px;
		line-height: 16px;

		&:nth-child(5){
			grid-column: 1 / 3;
		}
	}
}
.rodape__box__titulo{
	padding-left: 12px;
	position: relative;
	font-family: 'verdana';
	font-size: 14px;
	line-height: 16px;
	margin-bottom: 2px;

	&:before{
		width: 8px;
		height: 8px;
		content: '';
		position: absolute;
		top: calc(50% - 4px);
		left: 0px;
		background-color: #fff;
		border-radius: 50%;
	}
}
.rodape__box{
	margin-bottom: 16px;
}
.rodape__creditos{
	display: flex;
	justify-content: space-between;
	font-family: 'verdanamedium';
	font-size: 10px;
	line-height: 14px;
}
.gv8__box{
	display: flex;
	align-items: center;
	margin-top: 2px;

	svg{
		margin-left: 3px;
	}
}
.creditos__rodape{
	display: flex;
	justify-content: flex-end;
	font-size: 16px;
	line-height: 16px;
	align-items: center;

	a{
		display: flex;
		align-items: center;
		justify-content: center;
		border-radius: 50%;
		width: 39px;
		height: 39px;
		position: relative;
		color: #000;
		margin: 0px 2px;
		transition: all 0.3s linear;
		z-index: 10;

		&:hover{
			text-decoration: none;
			color: #fff;

			&:after{
				opacity: 0;
			}
		}

		&:after{
			position: absolute;
			left: 0px;
			top: 0px;
			background-color: #fff;
			border-radius: 50%;
			width: 100%;
			height: 100%;
			content: '';
			z-index: -1;
			transition: all 0.3s linear;
		}
		&:before{
			position: absolute;
			left: 0px;
			top: 0px;
			width: 100%;
			height: 100%;
			content: '';
			z-index: -2;
			transition: all 0.3s linear;
			background-image: url('../images/pontilhado__topo.png');
			background-repeat: no-repeat;
			background-position: center;
			background-size: 100% 100%;
		}
	}
}
@include media-breakpoint-up (lg) {
	.segura__endereco{
		padding-top: 56px;
	}
	.rodape__box__endereco{
		font-size: 14px;
		line-height: 20px;
	}
}
@include media-breakpoint-down (md) {
	.segura__atender__btn{
		display: flex;
		justify-content: center;
	}
	.titulo__atender{
		text-align: center;
	}
	.rodape__desc{
		text-align: center;
	}
	.rodape__titulo{
		text-align: center;
		padding-top: 40px;
	}
	.rodape__nav{
		display: flex;
		align-items: center;
		flex-direction: column;
	}
	.rodape__box{
		flex-direction: column;
		align-items: center;
		text-align: center;

		.rodape__box__titulo{
			display: inline-block;
		}
	}
	.rodape__creditos{
		flex-direction: column;
		align-items: center;
		text-align: center;
	}
	.gv8__box{
		justify-content: center;
		margin-bottom: 10px;
	}
}
