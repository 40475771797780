@font-face {
    font-family: 'verdana';
    src: url('../fonts/verdana-bold/verdana-bold-webfont.ttf');
    src: url('../fonts/verdana-bold/verdana-bold-webfont.ttf?#iefix') format('embedded-opentype'),
         url('../fonts/verdana-bold/verdana-bold-webfont.woff2') format('woff2'),
         url('../fonts/verdana-bold/verdana-bold-webfont.woff') format('woff'),
         url('../fonts/verdana-bold/verdana-bold-webfont.ttf') format('truetype'),
         url('../fonts/verdana-bold/verdana-bold-webfont.svg#verdana') format('svg');
    font-weight: normal;
    font-style: normal;

}

@font-face {
    font-family: 'verdanaextrabold';
    src: url('../fonts/verdana-extra-bold/verdana-extra-bold-webfont.ttf');
    src: url('../fonts/verdana-extra-bold/verdana-extra-bold-webfont.ttf?#iefix') format('embedded-opentype'),
         url('../fonts/verdana-extra-bold/verdana-extra-bold-webfont.woff2') format('woff2'),
         url('../fonts/verdana-extra-bold/verdana-extra-bold-webfont.woff') format('woff'),
         url('../fonts/verdana-extra-bold/verdana-extra-bold-webfont.ttf') format('truetype'),
         url('../fonts/verdana-extra-bold/verdana-extra-bold-webfont.svg#verdanaextrabold') format('svg');
    font-weight: normal;
    font-style: normal;

}

@font-face {
    font-family: 'verdanaitalic';
    src: url('../fonts/verdana-italic/verdana-italic-webfont.ttf');
    src: url('../fonts/verdana-italic/verdana-italic-webfont.ttf?#iefix') format('embedded-opentype'),
         url('../fonts/verdana-italic/verdana-italic-webfont.woff2') format('woff2'),
         url('../fonts/verdana-italic/verdana-italic-webfont.woff') format('woff'),
         url('../fonts/verdana-italic/verdana-italic-webfont.ttf') format('truetype'),
         url('../fonts/verdana-italic/verdana-italic-webfont.svg#verdanaitalic') format('svg');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'verdanalight';
    src: url('../fonts/verdana_light/verdana-light-webfont.ttf');
    src: url('../fonts/verdana_light/verdana-light-webfont.ttf?#iefix') format('embedded-opentype'),
         url('../fonts/verdana_light/verdana-light-webfont.woff2') format('woff2'),
         url('../fonts/verdana_light/verdana-light-webfont.woff') format('woff'),
         url('../fonts/verdana_light/verdana-light-webfont.ttf') format('truetype'),
         url('../fonts/verdana_light/verdana-light-webfont.svg#verdanalight') format('svg');
    font-weight: normal;
    font-style: normal;

}

@font-face {
    font-family: 'verdanamedium';
    src: url('#{$fonts}/verdana_medium/verdana-medium-webfont.ttf');
    src: url('#{$fonts}/verdana_medium/verdana-medium-webfont.ttf?#iefix') format('embedded-opentype'),
         url('#{$fonts}/verdana_medium/verdana-medium-webfont.woff2') format('woff2'),
         url('#{$fonts}/verdana_medium/verdana-medium-webfont.woff') format('woff'),
         url('#{$fonts}/verdana_medium/verdana-medium-webfont.ttf') format('truetype'),
         url('#{$fonts}/verdana_medium/verdana-medium-webfont.svg#verdanamedium') format('svg');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'verdana';
    src: url('../fonts/verdana_regular/verdana-regular-webfont.ttf');
    src: url('../fonts/verdana_regular/verdana-regular-webfont.ttf?#iefix') format('embedded-opentype'),
         url('../fonts/verdana_regular/verdana-regular-webfont.woff2') format('woff2'),
         url('../fonts/verdana_regular/verdana-regular-webfont.woff') format('woff'),
         url('../fonts/verdana_regular/verdana-regular-webfont.ttf') format('truetype'),
         url('../fonts/verdana_regular/verdana-regular-webfont.svg#verdana') format('svg');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'verdanasemibold';
    src: url('../fonts/verdana_semi_bold/verdana-semi-bold-webfont.ttf');
    src: url('../fonts/verdana_semi_bold/verdana-semi-bold-webfont.ttf?#iefix') format('embedded-opentype'),
         url('../fonts/verdana_semi_bold/verdana-semi-bold-webfont.woff2') format('woff2'),
         url('../fonts/verdana_semi_bold/verdana-semi-bold-webfont.woff') format('woff'),
         url('../fonts/verdana_semi_bold/verdana-semi-bold-webfont.ttf') format('truetype'),
         url('../fonts/verdana_semi_bold/verdana-semi-bold-webfont.svg#verdanasemibold') format('svg');
    font-weight: normal;
    font-style: normal;
}
