.contato__mapa{
	.embed-responsive{
		padding-bottom: 46%;
	}
}
.contato__desc{
	text-align: justify;
}
.contato__conteudo{
	.sub__secao{
		margin-bottom: 10px;
	}
}
.contato__form{
	display: grid;
	grid-template-columns: calc(50% - 10px) calc(50% - 10px);
	column-gap: 20px;
	padding-top: 24px;

	.form-group{
		margin-bottom: 20px;

		&:nth-child(5) {
			grid-column: 1 / 3;
		}
	}
	.segura__contato__btn{
		grid-column: 1 / 3;
		padding-top: 20px;
	}
}
.contato__input{
	display: block;
	width: 100%;
	height: auto;
	padding: 11px 22px 10px 22px;
	border: none;
	box-shadow: 0px 0px 6px rgba(#000, 0.5);
}
@include media-breakpoint-down (md) {
	.contato__conteudo{
		margin-bottom: 20px;
	}
}
