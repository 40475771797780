.breadcrumb__custom{
	background-color: #aeaaa1;
	color: #fff;
	padding: 10px 0px 8px 0px;
	font-family: 'verdana';
	font-size: 14px;
	line-height: 16px;

	.container{
		display: flex;
		justify-content: center;

		.breadcrumb__span{
			margin-right: 3px;
		}
	}
}
.interna{
	padding: 60px 0px 54px 0px;
}
.titulo__interna{
	font-family: 'verdana';
	color: #A91E23;
	font-size: 28px;
	line-height: 32px;
	margin-bottom: 18px;
}
.interna__desc{
	text-align: justify;
	color: #a6999a;
}
#sobre__carousel{
	display: block;
	width: 100%;
	margin-left: auto;
	margin-right: auto;
	position: relative;
	max-width: 540px;

	.carousel-controls{
		position: absolute;
		display: flex;
		align-items: center;
		right: 8px;
		bottom: 8px;
		z-index: 30;

		a{
			display: flex;
			align-items: center;
			justify-content: center;
			width: 48px;
			height: 48px;
			position: relative;
			border-radius: 50%;
			margin: 0px 4px;
			color: #fff;
			transition: all 0.3s linear;
			font-size: 18px;
			line-height: 18px;

			&:hover{
				text-decoration: none;
				color: #fff;

				&:before{
					opacity: 1;
				}
			}

			&:after{
				width: 100%;
				height: 100%;
				content: '';
				position: absolute;
				left: 0px;
				top: 0px;
				background-color: #A91E23;
				border-radius: 50%;
				z-index: -2;
			}
			&:before{
				width: 100%;
				height: 100%;
				content: '';
				position: absolute;
				left: 0px;
				top: 0px;
				background-image: url('../images/pontilhado.png');
				background-size: auto auto;
				background-position: center;
				background-repeat: no-repeat;
				z-index: -1;
				opacity: 0;
				transition: all 0.3s linear;
			}

			&.banner__next{
				span{
					margin-left: 2px;
				}
			}
		}
	}
}
.sobre__interna{
	.row{
		margin-bottom: 10px;
	}
}
